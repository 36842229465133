import React from "react";
import MyTripsController, { Props } from "./MyTripsController";
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import { CompletedSvg, UpcomingSvg, CancelledSvg, TravelDateSvg, DownloadSvg, NoProfileSvg } from "./assets";
import { AccountCircleOutlined, KeyboardArrowDown, KeyboardArrowUp, CheckCircle } from "@material-ui/icons";
import {
    Box,
    Button,
    Tab,
    Tabs,
    Typography,
    withStyles, Stepper, StepLabel, Step, StepConnector
} from "@material-ui/core";

const CustomTabs = withStyles({
    root: {
        background: 'white',
    },
    scroller: {
        overflow: 'auto !important',
        scrollbarColor: "#1764A0 #f1f1f1 !important",
        scrollbarWidth: "thin"
    },
    indicator: {
        backgroundColor: '#1764A0',
    },
})(Tabs);

const CustomTab = withStyles({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: 'bold',
        marginRight: '8px',
        width: "50%",

        background: 'white',
        '&:hover': {
            backgroundColor: 'rgba(233, 243, 252, 1)',
        },
        '&$selected': {
            backgroundColor: 'rgba(233, 243, 252, 1)',
            color: '#000',
        },
        '&:focus': {
            color: '#000',
        },
    },
    selected: {},
})(Tab);

const CustomStepIcon = withStyles({
    root: {
        color: '#1764A0',
        '&$active': {
            color: '#1764A0',
        },
        '&$completed': {
            color: '#1764A0',
        },
        width: "35px",
        height: "35px"
    },
    active: {},
    completed: {},
})(CheckCircle);

const CustomConnector = withStyles({
    alternativeLabel: {
        top: 14,
    },
    active: {
        '& $line': {
            borderColor: '#1764A0',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#1764A0',
        },
    },
    line: {
        borderTopWidth: 8,
        borderColor: '#1764A0',
    },
})(StepConnector);

const CustomStepLabel = withStyles({
    label: {
        fontSize: '14px',
        fontWeight: 'bold',
    },
})(StepLabel);

export default class MyTrips extends MyTripsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    getUpcomingUI = () => {
        
        return <Box data-test-id="upcoming-container" sx={webStyleMyTrip.flightDetailContainer}>
            <Box sx={webStyleMyTrip.upComingDetails} style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <Box sx={webStyleMyTrip.flightDataCont} style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                    <Typography style={webStyleMyTrip.flightTypeHeading}>Departure Flight -6 June, Fri</Typography>
                    <Box 
                    sx={webStyleMyTrip.upcomingDetailsCont} style={{
                        display: 'flex', 
                        justifyContent: 'space-between', flexWrap: "wrap-reverse",
                        gap: "24px"
                    }}>
                        <div 
                        
                        style={{ display: 'flex', flexDirection: 'column', 
                        gap: "8px", justifyContent: 'flex-start' }}>
                            <Typography style=
                            {webStyleMyTrip.flight_content}>
                                6 June 24, 12:20 PM</Typography>
                            <Typography style={webStyleMyTrip.flight_heading}>
                                DEL - New Delhi</Typography>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column',
                         gap: "8px", justifyContent: 'flex-start' }}>
                            <Typography style={webStyleMyTrip.flight_content}>
                                6 June 24, 2:20 PM</Typography>
                            <Typography 
                            
                            style={webStyleMyTrip.flight_heading}>
                                BOM - Mumbai</Typography>
                        </div>
                        <Box 
                        sx={webStyleMyTrip.flightDetailsCont} style={{ display: 'flex', gap: "8px", justifyContent: 'flex-start' }}>
                            <Typography 
                            
                            style={{ ...webStyleMyTrip.flight_content, display: 'flex', alignItems: 'center', marginLeft: '3px' }}><img style={{
                                objectFit: 'cover', 
                                
                                width: '26px',
                                height: '26px',
                                marginRight: '8px',
                                borderRadius: '50%',
                            }} src="https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg" />Indigo</Typography>
                            <Typography style={{ ...webStyleMyTrip.flight_content, display: 'flex', alignItems: 'center' }}><AccountCircleOutlined style={{ color: "#64748B", height: '28px',width:'28px',marginRight:'8px' }} /> Rahul Das</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={webStyleMyTrip.flightDataCont} 
                

                style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                    <Typography style={webStyleMyTrip.flightTypeHeading}>Departure Flight - 6 June, Fri</Typography>
                    <Box 
                    
                    sx={webStyleMyTrip.upcomingDetailsCont} 
                    style={{
                        display: 'flex', justifyContent: 'space-between', flexWrap: "wrap-reverse",
                        gap: "24px"
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: "8px", justifyContent: 'flex-start' }}>
                            <Typography
                            
                            style={webStyleMyTrip.flight_content}>
                                6 June 24, 12:20 PM</Typography>
                            <Typography style={webStyleMyTrip.flight_heading}>DEL - New Delhi
                            
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', 
                        
                        flexDirection: 'column', gap: "8px", justifyContent: 'flex-start' }}>
                            <Typography style={webStyleMyTrip.flight_content}>
                                6 June 24, 2:20 PM</Typography>
                            <Typography style={webStyleMyTrip.flight_heading}>
                                
                                
                                BOM - Mumbai</Typography>
                        </div>
                        <Box
                        
                        sx={webStyleMyTrip.flightDetailsCont} style={{ display: 'flex', gap: "8px", justifyContent: 'flex-start' }}>
                            <Typography 
                            
                            style={{ ...webStyleMyTrip.flight_content, display: 'flex', alignItems: 'center', marginLeft: '3px' }}><img style={{
                                objectFit: 'cover', width: '26px',
                                height: '26px',


                                marginRight: '8px',
                                borderRadius: '50%',
                            }} 
                            src="https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg" />Indigo</Typography>
                            <Typography style={{ ...webStyleMyTrip.flight_content, display: 'flex', alignItems: 'center' }}><AccountCircleOutlined style={{ color: "#64748B",height: '28px',
                            width:'28px',
                            marginRight:'8px'}} /> Rahul Das</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box style={{ display: 'flex' }} sx={webStyleMyTrip.bookingBtnContainer}>
                <Button data-test-id='cancel-booking-btn' onClick={()=> this.handleCancelBooking('1')} style={{ height: '44px', textTransform: "none", fontWeight: 700, fontSize: '16px', display: 'flex', alignItems: "center", gap: "10px", justifyContent: "flex-start" }}> <img style={{ marginRight: "14px", width:"28px" }} src={CancelledSvg} /> Cancel Booking</Button>
                <Button data-test-id='change-travel-date' style={{ height: '44px', textTransform: "none", fontWeight: 700, fontSize: '16px', display: 'flex', alignItems: "center", gap: "10px", justifyContent: "flex-start" }}  onClick={()=> this.handleChangeDate('1')}><img src={TravelDateSvg} style={{ marginRight: "10px" }} />Change Travel Date</Button>
            </Box>
        </Box>
    }

    getCompletedUI = () => {
        return (
            <Box data-test-id="completed-container" sx={webStyleMyTrip.flightDetailContainer}>
                <div style={{ width: '100%', display: "flex", flexDirection: "column", gap: "24px", paddingRight: "32px" }}>
                    <Box sx={webStyleMyTrip.flightDataCont} style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                        <Typography style={webStyleMyTrip.flightTypeHeading}>Departure Flight - 6 June, Fri</Typography>
                        <Box sx={webStyleMyTrip.cancelDetails} style={{
                            display: 'flex', justifyContent: 'space-between', flexWrap: "wrap-reverse",
                            gap: "24px"
                        }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: "8px", justifyContent: 'flex-start' }}>
                                <Typography style={webStyleMyTrip.flight_content}>6 June 24, 12:20 PM</Typography>
                                <Typography style={webStyleMyTrip.flight_heading}>DEL - New Delhi</Typography>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: "8px", justifyContent: 'flex-start' }}>
                                <Typography style={webStyleMyTrip.flight_content}>6 June 24, 2:20 PM</Typography>
                                <Typography style={webStyleMyTrip.flight_heading}>BOM - Mumbai</Typography>
                            </div>
                            <Box sx={webStyleMyTrip.flightDetailsCont} style={{ display: 'flex', gap: "8px", justifyContent: 'flex-start' }}>
                                <Typography 
                                style={{ ...webStyleMyTrip.flight_content, 
                                    display: 'flex', alignItems: 'center',
                                     marginLeft: '3px' }}><img style={{
                                    objectFit: 'cover', 
                                    width: '26px',
                                    height: '26px',

                                    marginRight: '8px',

                                    borderRadius: '50%',
                                }} 
                                src="https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg" />Indigo
                                
                                </Typography>
                                <Typography
                                 style={{ ...webStyleMyTrip.flight_content, display: 'flex', 
                                 alignItems: 'center' }}><AccountCircleOutlined style={{ color: "#64748B",
                                 height: '28px',
                                 width:'28px',marginRight:'8px' }} /> Rahul Das</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box 
                    
                    sx={webStyleMyTrip.flightDataCont} 
                    style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                        <Typography 
                        
                        
                        style={webStyleMyTrip.flightTypeHeading}>Departure Flight - 6 June, Fri
                        </Typography>
                        <Box 
                        
                        sx={webStyleMyTrip.cancelDetails} style={{
                            display: 'flex', justifyContent: 'space-between', flexWrap: "wrap-reverse",
                            gap: "24px"
                        }}>
                            <div 
                            style={{ display: 'flex', flexDirection: 'column', gap: "8px", justifyContent: 'flex-start' }}>
                                <Typography style={webStyleMyTrip.flight_content}>
                                    
                                    6 June 24, 12:20 PM</Typography>
                                <Typography style={webStyleMyTrip.flight_heading}>
                                    DEL - New Delhi</Typography>
                            </div>
                            <div 
                            
                            style={{ display: 'flex', flexDirection: 'column', gap: "8px", justifyContent: 'flex-start' }}>
                                <Typography style={webStyleMyTrip.flight_content}>
                                    6 June 24, 2:20 PM</Typography>
                                <Typography
                                
                                style={webStyleMyTrip.flight_heading}>BOM - Mumbai</Typography>
                            </div>
                            <Box
                            
                            sx={webStyleMyTrip.flightDetailsCont} style={{ display: 'flex', gap: "8px", justifyContent: 'flex-start' }}>
                                <Typography style={{ ...webStyleMyTrip.flight_content, display: 'flex', alignItems: 'center', marginLeft: '3px' }}><img style={{
                                    objectFit: 'cover', width: '26px',
                                    height: '26px',
                                    marginRight: '8px',
                                    borderRadius: '50%',
                                }} src="https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg" />Indigo</Typography>
                                <Typography style={{ ...webStyleMyTrip.flight_content, display: 'flex', alignItems: 'center' }}><AccountCircleOutlined style={{ color: "#64748B",
                                height: '28px',width:'28px',
                                marginRight:'8px' }} /> Rahul Das</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={webStyleMyTrip.flightDataCont} style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                        <Typography 
                        
                        style={webStyleMyTrip.flightTypeHeading}>Departure Flight - 6 June, Fri</Typography>
                        <Box
                        
                        sx={webStyleMyTrip.cancelDetails} style={{
                            display: 'flex', justifyContent: 'space-between', flexWrap: "wrap-reverse",
                            gap: "24px"
                        }}>
                            <div
                            
                            style={{ display: 'flex', flexDirection: 'column', gap: "8px", justifyContent: 'flex-start' }}>
                                <Typography style={webStyleMyTrip.flight_content}>6 June 24, 12:20 PM</Typography>
                                <Typography style={webStyleMyTrip.flight_heading}>DEL - New Delhi</Typography>
                            </div>
                            <div 
                            style={{ display: 'flex', flexDirection: 'column', gap: "8px", justifyContent: 'flex-start' }}>
                                <Typography
                                 style={webStyleMyTrip.flight_content}>6 June 24, 2:20 PM</Typography>
                                <Typography
                                
                                style={webStyleMyTrip.flight_heading}>BOM - Mumbai</Typography>
                            </div>
                            <Box
                            
                            sx={webStyleMyTrip.flightDetailsCont} style={{ display: 'flex', gap: "8px", justifyContent: 'flex-start' }}>
                                <Typography 
                                
                                style={{ ...webStyleMyTrip.flight_content, display: 'flex', alignItems: 'center', marginLeft: '3px' }}><img style={{
                                    objectFit: 'cover', width: '26px',
                                    height: '26px',
                                    marginRight: '8px',
                                    borderRadius: '50%',
                                }} src="https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg" />Indigo</Typography>
                                <Typography 
                                
                                style={{ ...webStyleMyTrip.flight_content, display: 'flex', alignItems: 'center' }}><AccountCircleOutlined style={{ color: "#64748B",height: '28px',width:'28px',
                                marginRight:'8px'}} /> Rahul Das</Typography>
                            </Box>
                        </Box>
                    </Box>
                </div>
            </Box>
        )
    }
    getCancelledUI = (index: number) => {
        return (
            <Box data-test-id="cancelled-container" sx={webStyleMyTrip.detailsContainer}>
                <Box sx={webStyleMyTrip.ticketHeader} style={{ display: "flex", justifyContent: "space-between", boxShadow: "0px 2px 10px 0px #0000001A", flexWrap: 'wrap', gap: '20px' }}>
                    <div>
                        <Typography style={webStyleMyTrip.flight_heading}>New Delhi - Mumbai</Typography>
                        <Typography style={{...webStyleMyTrip.flight_content,marginTop:"8px"}}>One way Flight | Booking ID - NXERHUIOP1226TB556</Typography>
                    </div>
                </Box>
                <div style={{ display: 'flex', flexDirection: "column" }}>
                    <Box sx={webStyleMyTrip.flightDataContCancel} style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                        <Typography style={webStyleMyTrip.flightTypeHeading}>
                            Departure Flight -6 June, Fri</Typography>
                        <Box sx={webStyleMyTrip.cancelDetails}
                            style={{
                                display: 'flex', justifyContent: 'space-between', flexWrap: "wrap-reverse",
                                gap: "24px"
                            }}>
                            <div 
                            style={{ display: 'flex', flexDirection: 'column', gap: "8px", justifyContent: 'flex-start' }}>
                                <Typography 
                                style={webStyleMyTrip.flight_content}>
                                    6 June 24, 12:20 PM</Typography>
                                <Typography 
                                style={webStyleMyTrip.flight_heading}>
                                    DEL - New Delhi</Typography>
                            </div>
                            <div
                             style={{ display: 'flex', flexDirection: 'column', gap: "8px", justifyContent: 'flex-start' }}>
                                <Typography style={webStyleMyTrip.flight_content}>
                                    6 June 24, 2:20 PM</Typography>
                                <Typography 
                                style={webStyleMyTrip.flight_heading}>
                                    BOM - Mumbai</Typography>
                            </div>
                            <Box 
                            sx={webStyleMyTrip.flightDetailsCont}
                                style={{ display: 'flex', gap: "8px", justifyContent: 'flex-start' }}>
                                <Typography
                                    style={{ ...webStyleMyTrip.flight_content, display: 'flex', alignItems: 'center', marginLeft: '3px' }}><img style={{
                                        objectFit: 'cover', width: '26px',
                                        height: '26px',
                                        marginRight: '8px',
                                        borderRadius: '50%',
                                    }} src="https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg" />Indigo</Typography>
                                <Typography style={{ ...webStyleMyTrip.flight_content, display: 'flex', alignItems: 'center' }}><AccountCircleOutlined style={{ color: "#64748B", height: '28px',
                                width:'28px',marginRight:'8px' }} /> Rahul Das</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <div style={{ padding: "16px", borderTop: "1px solid var(--grey-dark, #94A3B8)", display: 'flex', justifyContent: "flex-end" }}>
                        <Button data-test-id={`view-cancelled${index}`} style={{ color: "#1764A0", height: '44px', textTransform: "none", fontWeight: 700, fontSize: '16px', display: 'flex', alignItems: "center", gap: "10px", justifyContent: "flex-start" }} endIcon={this.state.displayCancelledIndex === index ? <KeyboardArrowUp style={{ color: '#1764A0' }} /> : <KeyboardArrowDown style={{ color: '#1764A0' }} />}
                            onClick={() => this.handleViewCancelledTrip(index)}
                        >View Refund Status</Button>
                    </div>
                </div>
            </Box>
        )
    }
    cancelStatusUI = () => {
        return (
            <Box data-test-id="cancelled-status-container" style={{ marginTop: "10px" }} sx={webStyleMyTrip.detailsContainer}>
                <div style={{ display: "flex", justifyContent: "space-between", boxShadow: "0px 2px 10px 0px #0000001A", padding: '32px' }}>
                    <div>
                        <Typography style={{fontSize:'24px',fontWeight:700}}>Amount Refunded</Typography>
                        <Typography style={webStyleMyTrip.flight_content}>Total refund Processed - $ 3109 </Typography>
                    </div>
                </div>
                <Box sx={webStyleMyTrip.flightDetailContainer}>
                    <Box sx={{ paddingRight: { sm: "10px", md: "20px", lg: "32px" } }} style={{ width: '100%', display: "flex", flexDirection: "column", gap: "24px" }}>
                        <Box sx={{ ...webStyleMyTrip.refundHeading }} style={{ display: "flex", alignItems: "flex-start", gap: "10px" }}>
                            <img src={NoProfileSvg} />
                            <Typography style={{ fontWeight: 600 }} >Booking Of Rahul Das was cancelled. A refund of $3,109 has been processed. </Typography>
                        </Box>
                        <Stepper activeStep={this.state.refundSteps.length} alternativeLabel connector={<CustomConnector />}>
                            {this.state.refundSteps.map((step, index) => (
                                <Step key={index}>
                                    <CustomStepLabel StepIconComponent={CustomStepIcon}>
                                        <Box sx={webStyleMyTrip.stepperDetails_head}>
                                            <Typography style={{ fontWeight: 700 }} variant="body2" component="div">
                                                {step.label}
                                            </Typography>
                                        </Box>
                                        <Box sx={webStyleMyTrip.stepperDetails_content}>
                                            <Typography style={{ fontWeight: 500 }} variant="body2" component="div">
                                                {step.date}
                                            </Typography>
                                        </Box>
                                    </CustomStepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Box>
            </Box>

        )
    }

    render() {
        return (
            // Customizable Area Start
            <div style={{ position: "relative" }}>
                <Box sx={webStyleMyTrip.headerContainer}>
                    <HeaderMenuBlock navigation={this.props.navigation} id={"MyTrips"} />
                </Box>
                <Box sx={webStyleMyTrip.mainContainer} style={{ position: "absolute", top: "100px", display: "flex", flexDirection: "column", gap: "58px" }}>
                    <Typography style={webStyleMyTrip.title}>Planned Trips</Typography>
                    <Box sx={webStyleMyTrip.boxContainer}>
                        <div style={{ borderBottom: "1px solid #E2E8F0", boxShadow: "0px 2px 10px 0px #0000001A", paddingLeft: "32px", paddingBottom: "27px", paddingTop: "42px" }}>
                            <Typography style={webStyleMyTrip.subTitle}>My Trips</Typography>
                        </div>
                        <div style={{ borderBottom: "1px solid #E2E8F01", boxShadow: "0px 2px 10px 0px #0000001A", paddingLeft: '8px', paddingTop: "11px", paddingBottom: '11px' }}>
                            <CustomTabs data-test-id="mytrip-tabs" value={this.state.selectedTab} onChange={this.handleTabChange}>
                                <CustomTab label={<span style={{ display: "flex", alignItems: "center", gap: "10px" }}><img src={UpcomingSvg} />Upcoming</span>} value={'Upcoming'} />
                                <CustomTab label={<span style={{ display: "flex", alignItems: "center", gap: "10px" }}><img src={CancelledSvg} />Cancelled</span>} value={'Cancelled'} />
                                <CustomTab label={<span style={{ display: "flex", alignItems: "center", gap: "10px" }}><img src={CompletedSvg} />Completed</span>} value={'Completed'} />
                            </CustomTabs>
                        </div>
                        <Box sx={webStyleMyTrip.dataContainer}>
                            {this.state.selectedTab === 'Cancelled' ? this.state.cancelledTripArr.map((trip, index: number) => <div style={{ marginBottom: "10px" }}>{this.getCancelledUI(index)}
                                {this.state.displayCancelledIndex === index && this.cancelStatusUI()}</div>) :
                                <Box sx={webStyleMyTrip.detailsContainer}>
                                    <Box sx={webStyleMyTrip.ticketHeader} style={{ display: "flex", justifyContent: "space-between", boxShadow: "0px 2px 10px 0px #0000001A", flexWrap: 'wrap', gap: '20px' }}>
                                        <div>
                                            <Typography style={webStyleMyTrip.flight_heading}>New Delhi - Mumbai</Typography>
                                            <Typography style={{...webStyleMyTrip.flight_content,marginTop:"8px"}}>One way Flight | Booking ID - NXERHUIOP1226TB556</Typography>
                                        </div>
                                        <Box style={{ display: 'flex', gap: '16px', flexWrap: "wrap" }}>
                                            <Button variant="contained" style={{ height: '44px', backgroundColor: '#1764A0', textTransform: "none", color: 'white', fontWeight: 700, fontSize: '16px' }} startIcon={<img src={DownloadSvg} />}>
                                                Download Ticket
                                            </Button>
                                            <Button variant="contained" style={{ height: '44px', backgroundColor: '#1764A0', textTransform: "none", color: 'white', fontWeight: 700, fontSize: '16px' }} startIcon={<img src={DownloadSvg} />}>
                                                Download Invoice
                                            </Button>
                                        </Box>
                                    </Box>
                                    {this.state.selectedTab === 'Upcoming' && this.getUpcomingUI()}
                                    {this.state.selectedTab === 'Completed' && this.getCompletedUI()}
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </div>
            // Customizable Area End
        );
    }
}

const webStyleMyTrip = {
    dataContainer: {
        padding: {
            xs: "36px 8px 20px",
            sm: "36px 20px 20px",
            md: "36px 36px 20px",
            lg: "36px 56px 20px"
        }
    },
    flightDetailsCont: {
        flexDirection: {
            xs: "row",
            sm: 'column',
            md: "column",
            lg: "column",
        }
    },
    ticketHeader: {
        padding: {
            xs: "8px",
            sm: "16px",
            md: "32px",
            lg: '32px',
        }

    },
    headerContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "56px",
        overFlowX: 'hidden',
        height: {
            xs: '930px',
            sm: '800px',
            md: '580px',
            lg: '490px'
        },
        background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
    },
    cancelDetails: {
        width: {
            lg: "50%",
            md: "50%",
        },
    },
    upcomingDetailsCont: {
        width: "100%"
    },
    upComingDetails: {
        width: {
            sm: "95%",
            md: "50%",
            lg: "50%",
        }
    },
    flightDataCont: {
        padding: {
            xs: "8px"
        }
    },
    flightDataContCancel: {
        padding: {
            xs: "8px"
        },
        paddingLeft: {
            sm: "10px",
            md: "20px",
            lg: "32px"
        },
        paddingTop: "50px !important",
        paddingBottom: "50px !important",
    },
    title: {
        fontWeight: 700,
        fontSize: 32,
        color: "white",
        marginLeft: "32px"
    },
    stepperDetails_head: {
        fontSize: {
            sm: "12px",
            md: "14px",
            lg: "16px"
        },
        fontWeight: 700
    },
    stepperDetails_content: {
        fontSize: {
            sm: "10px",
            md: "12px",
            lg: "14px"
        },
        fontWeight: 500
    },
    flight_heading: {
        fontSize: "16px",
        fontWeight: 700
    },
    flight_content: {
        fontSize: "14px",
        fontWeight: 500
    },
    boxContainer: {
        boxShadow: "0px 4px 10px 0px #00000029",
        borderRadius: "8px",
        width: "100%",
        background: "white",
        minHeight: "500px"
    },
    detailsContainer: {
        boxShadow: "0px 4px 10px 0px #00000029",
        borderRadius: "8px",
        width: "100%",
        background: "white",
        minHeight: "300px"
    },
    subTitle: {
        fontWeight: 700,
        fontSize: 24,
    },
    mainContainer: {
        width: {
            xs: "96%",
            sm: "97%",
            md: "98%",
            lg: "97%"
        },
        padding: {
            xs: "10px",
            lg: "24px"
        }
    },
    flightDetailContainer: {
        paddingLeft: {
            sm: "10px",
            md: "20px",
            lg: "32px"
        },
        paddingTop: "40px",
        paddingBottom: "35px",
        display: 'flex',
        alignItems: "center",
        gridGap: {
            xs: "20px",
            md: "100px",
            lg: "100px"
        },
        flexWrap: "wrap"
    },
    flightTypeHeading: {
        fontWeight: 700,
        fontSize: "16px",
        color: "#64748B"
    },
    refundHeading: {
        fontWeight: 600,
        fontSize: {
            sm: "14px",
            md: "15px",
            lg: "16px"
        },
        paddingLeft:{
            xs:"32px",
            sm:"22px"
        },
        paddingRight:{
            xs:"32px",
            sm:"22px"
        }
    },
    bookingBtnContainer: {
        flexDirection: {
            xs: "column",
            sm: "row",
            md: "column",
            lg: "column",
        },
        padding: {
            xs: "8px"
        },
        gap: {
            xs: "0px",
            lg: '30px'
        }
    }
}