
import { Grid, TextField, Typography, Box, Button, Popover, Menu, MenuList, MenuItem, styled, CircularProgress, } from '@material-ui/core';
import React from 'react'
import { SearchText } from '../../blocks/catalogue/src/MultiCitySearchingController';
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { cancleCity, errorIcon, } from '../../blocks/catalogue/src/assets';
import Calender from "./Calender";
import SingleCalender from "./SingleCalender";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
const configJSON = require("../../blocks/catalogue/src/config.js");


interface City {
    airportFrom: SearchText
    airportTo: SearchText
    departureDate: Date | null;
}
interface DateRange {
    selection: {
        startDate: Date;
        endDate: Date;
    };
}
interface MultiCityHeaderProps {
    state: {
        fromObj: {
            state: string,
            country: string,
            flight: string,
            stateCode: string
        };
        toObj: {
            state: string,
            country: string,
            flight: string,
            stateCode: string
        },
        cities: City[];
        searchFromMultiCity: string
        searchToMultiCity: string
        filteredFromItems: SearchText[];
        filteredToItems: SearchText[];
        fromInputSearchValue: string
        toInputSearchValue: string
        showTrip: boolean;
        fullTravelObj: {
            countAdult: number,
            countChild: number,
            travelStatus: string,
            countInfant: number
        }
        anchorEl: HTMLElement | null;
        toAnchorEl: HTMLElement | null;
        selectedTripType: string;
        startDate: Date | null;
        endDate: Date | null;
        endDate1: Date | null;
        selectedOption: string;
        isOpen: boolean;
        setAnchorAi: Element | null;
        countAdult: number;
        travelStatus: string;
        countChild: number;
        countInfant: number;
        anchorElTripType: Element | null
        anchorElClass: Element | null
        error: string
        currentIndex: number

    };
    handleClick: (event: React.MouseEvent<HTMLElement>, index: number) => void;
    handleClickTo: (event: React.MouseEvent<HTMLElement>, index: number) => void
    handleSelectTriptype: (item: string) => void
    handleClosePopup: () => void;
    handleInputChangeTo: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void
    handleSingleClose: (item: SearchText, index: number) => void
    handleCloseTo: (item: SearchText, index: number) => void
    reverseFunct: (index: number) => void
    togglePicker: (picker: string, event: React.MouseEvent<HTMLElement>, index: number) => void
    handleOpenTripType: (event: React.MouseEvent<HTMLElement>) => void
    handleRemoveCity: (indexNumber: number) => void
    handleAddCity: () => void
    handleCloseCustomAi: () => void
    handleCancel: () => void
    handleApply: (index: number) => void
    closeTripTypeMenu: () => void
    openTripOption: () => void
    openClassCategory: (event: React.MouseEvent<HTMLElement>) => void
    closeClassCategory: () => void
    handleClickStatus: (item: string) => void
    handleDateChange1: (ranges: DateRange) => void
    totalTravellers: () => void
    handleDateChange: (ranges: DateRange) => void
    countDecrease: () => void
    countIncrease: () => void
    countChildIncrease: () => void
    countChildDecrease: () => void
    countChildInfantDecrease: () => void
    countChildInfantIncrease: () => void
    handleCancelTravelButton: () => void
    handleDoneButton: () => void

}
const MulticyFlightHeader = ({ state, handleClick,handleOpenTripType, handleDoneButton, handleClickStatus, handleCancelTravelButton, countDecrease, countChildIncrease, countChildInfantIncrease, countChildInfantDecrease, countChildDecrease, countIncrease, handleRemoveCity, closeClassCategory, totalTravellers, openClassCategory, openTripOption, closeTripTypeMenu, handleSelectTriptype, handleDateChange1, handleDateChange, handleCancel, handleApply, handleCloseCustomAi, handleAddCity, togglePicker, handleClosePopup, handleInputChange, handleCloseTo, handleClickTo, handleInputChangeTo, handleSingleClose, reverseFunct }: MultiCityHeaderProps) => {
    const formatDatePart = (value: Date, part: string) => {
        let dateValue: any = formatDate(value)
        if (dateValue) {
            const parts = dateValue.split(' ');
            switch (part) {
                case "day":
                    return parts[0];
                case "month":
                    return parts[1];
                case "year":
                    return parts[2];
            }
        }
    };
    const formatDate = (date: Date) => {
        if (date) {
            const dayaa = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear().toString().slice(-2);
            const weekday = date.toLocaleString('default', { weekday: 'long' });
            return `${dayaa} ${month}'${year} ${weekday}`;
        }
    };
    const fromTextUI = (object: SearchText) => {
        return (
            <>
                {object && object.flight ? (
                    <div style={{
                        position: "absolute",
                        width: "94%",
                        background: "rgb(19, 81, 130)", padding: "5px 0"
                    }}>
                        <span className="textcalenderday" style={{ fontWeight: "700", fontSize: "32px", color: "#fff" }}>
                            {object.state}</span>
                        <span className="textcalendername" style={{
                            fontWeight: "500", fontSize: "16px",
                            display: "block", marginTop: "10px", color: "#fff", width: "96%"
                        }}>{object.stateCode},{object.flight}</span>
                    </div>
                ) : ''}
            </>
        )
    }
    const toInputText = (object: SearchText) => {
        return (
            <>
                {object && object.flight ? (
                    <div style={{
                        position: "absolute", width: "92%",
                        background: "rgb(19, 81, 130)",
                        left: "1px",
                        padding: "5px 0"
                    }}>

                        <span className="textcalenderday" style={{
                            fontWeight: "700", fontSize: "32px",
                            color: "#fff"
                        }}>{object.state}</span>
                        <span className="textcalendername" style={{ fontWeight: "500", fontSize: "16px", display: "block", marginTop: "10px", color: "#fff", }}>{object.stateCode},{object.flight}</span>
                    </div>
                ) : ''}
            </>
        )
    }
    const allButtonList = () => {
        return (
            <Box style={{ padding: "12px" }}>
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <div className="buttonContainer">
                            <button
                                className={`buttonEconomy ${state.travelStatus === 'PremiumEconomy' ? 'active' : ''}`}
                                data-test-id="handleClickStatus"
                                onClick={() => handleClickStatus('PremiumEconomy')}
                            >
                                Premium /
                                Economy
                            </button>
                            <button
                                className={`buttonEconomy ${state.travelStatus === 'PremiumFirst' ? 'active' : ''}`}
                                data-test-id="handleClickStatus2"
                                onClick={() => handleClickStatus('PremiumFirst')}
                            >
                                Premium /
                                First
                            </button>
                            <button
                                className={`buttonEconomy ${state.travelStatus === 'First' ? 'active' : ''}`}
                                data-test-id="handleClickStatus3"
                                onClick={() => handleClickStatus('First')}
                            >
                                First
                            </button>
                            <button
                                className={`buttonEconomy ${state.travelStatus === 'Business' ? 'active' : ''}`}
                                data-test-id="handleClickStatus4"
                                onClick={() => handleClickStatus('Business')}
                            >
                                Business
                            </button>
                            <button
                                className={`buttonEconomy ${state.travelStatus === 'Economy' ? 'active' : ''}`}
                                data-test-id="handleClickStatus5"
                                onClick={() => handleClickStatus('Economy')}
                            >
                                Economy
                            </button>

                        </div>
                    </Grid>
                </Grid>
            </Box>
        )
    }
    const renderInputTripList = () => {

        return (
            <>
                {state.showTrip &&
                    state.cities.map((city, indexNumber) => (
                        <>
                            <Grid item xs={12} sm={12} md={3} lg={2}>
                                <Typography className="tripText">
                                    Trip {indexNumber + 1}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={8}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={6} lg={4} style={{ position: "relative", }}>
                                        <TextField
                                            autoComplete="off"
                                            onBlur={() => setTimeout(() => {
                                                handleClosePopup()
                                            }, 300)}
                                            name="from"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, indexNumber)}
                                            style={{ cursor: "pointer" }}
                                            label={(
                                                <>
                                                    <span className="catalogue-heading">From</span>
                                                    {fromTextUI(city.airportFrom)}
                                                </>
                                            )}
                                            variant="outlined"
                                            value={state.currentIndex === indexNumber ? state.searchFromMultiCity :""}
                                            placeholder={"Enter city/ Airport name"}
                                            data-test-id="inputCityFrom"
                                            onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e, indexNumber)}
                                            fullWidth
                                            InputProps={{
                                                classes: {
                                                    input: "catalogue-header-box",
                                                    root: "custom-input-root",
                                                },

                                            }}
                                            className="catalogue-header-box"
                                            InputLabelProps={{
                                                className: "fontFrom fontLabel",
                                                shrink: true,
                                                style: { transform: "translate(14px, 7px)", cursor: "pointer", color: "#000", fontWeight: "700", fontSize: "16px", width: "100%" }
                                            }}
                                        />
                                        {(state.filteredFromItems.length > 0 && Boolean(state.anchorEl)) && state.currentIndex === indexNumber &&
                                            <Box className="yscrollwindow fromDropdown" sx={{ width: { xs: "94%", sm: "94%", md: "95%", lg: "96%" } }} style={{ padding: "10px" }}>
                                                {state.filteredFromItems.map((itemObj: SearchText, index: number) => (
                                                    <Box data-test-id={`fromMenu${index}`} style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => handleSingleClose(itemObj, indexNumber)}>
                                                        <Box className="highlight-item" style={{ paddingTop: "10px", width: "100%", cursor: 'pointer' }}>
                                                            <Grid container spacing={2}
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: "space-between",
                                                                    padding: "12px"
                                                                }}>
                                                                <Box
                                                                    style={{ display: 'flex', gap: '10px' }}> <Grid item> <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} /> </Grid>
                                                                    <Grid item >
                                                                        <Typography style={{ color: "#000", paddingBottom: "12px", fontWeight: "600", fontSize: "12px", }} variant="body1">{itemObj.state}</Typography>
                                                                        <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: "600" }} variant="body1">{itemObj.flight}</Typography>

                                                                    </Grid></Box>

                                                                <Grid item>
                                                                    <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: "600" }}
                                                                        variant="body1">{itemObj.stateCode}</Typography> </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        }
                                        <Box
                                            data-test-id="reverseFunct"
                                            onClick={()=> reverseFunct(indexNumber)}
                                            className="reversChangeIcon">
                                            <SyncAltIcon style={{
                                                color: "rgb(23, 100, 160)",
                                                cursor: "pointer"
                                            }} />
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={4} style={{ position: 'relative' }}><TextField
                                            name="to"
                                            onBlur={() =>

                                                setTimeout(() => {
                                                    handleClosePopup()
                                                },
                                                    300)}

                                            label={(
                                                <>
                                                    <span className="catalogue-heading">To</span>
                                                    {toInputText(city.airportTo)}
                                                </>
                                            )}
                                            onChange={handleInputChangeTo}
                                            data-test-id="inputCityTo"
                                            value={state.currentIndex === indexNumber ? state.searchToMultiCity :""}
                                            onClick={(e: React.MouseEvent<HTMLElement>) => handleClickTo(e, indexNumber)}
                                            variant="outlined"
                                            autoComplete="off"
                                            placeholder={"Enter city/ Airport name"}
                                            fullWidth
                                            className="catalogue-header-box"
                                            InputProps={{
                                                classes: {
                                                    root: "custom-input-root",
                                                    input: "catalogue-header-box",
                                                }
                                            }}
                                            InputLabelProps={{
                                                className: "fontFrom fontLabel",
                                                shrink: true,
                                                style: { overflow: "hidden", height: "100%", transform: "translate(14px, 7px)", color: "#000", fontWeight: "700", fontSize: "16px", width: "100%" }
                                            }}
                                        />
                                        {(state.filteredToItems.length > 0 && Boolean(state.toAnchorEl)) && state.currentIndex === indexNumber && <Box sx={{
                                            width: {
                                                xs: "94%", sm: "94%", md: "95%",
                                                lg: "96%"
                                            }
                                        }} className="yscrollwindow fromDropdown" style={{ padding: "10px" }}>
                                            {state.filteredToItems.map((item: SearchText, index: number) => (
                                                <Box data-test-id={`toMenu${index}`} style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => handleCloseTo(item, indexNumber)}>
                                                    <Box style={{ width: "100%", paddingTop: "10px" }} className="highlight-item">
                                                        <Grid container spacing={2} style={{ display: 'flex', justifyContent: "space-between", padding: "12px" }}>
                                                            <Box style={{ display: 'flex', gap: '10px' }}>
                                                                <Grid item>
                                                                    <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography style={{ color: "#000", fontSize: "12px", paddingBottom: "12px", fontWeight: "600" }} variant="body1">{item.state}</Typography>
                                                                    <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: "600" }} variant="body1">{item.flight}</Typography>
                                                                </Grid>
                                                            </Box>
                                                            <Grid item>
                                                                <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: "600" }} variant="body1">{item.stateCode}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>}
                                    </Grid>
                                    <Grid item xs={12} sm={state.selectedTripType === "Round Trip" ? 5 : 12} md={12} lg={4}>
                                        <TextField style={{ cursor: "pointer" }} name="departureDate"
                                            className="catalogue-header-box"

                                            label={(
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>

                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: '100%' }}>
                                                        <span className="catalogue-heading"> Departure Date</span>
                                                        <ExpandMoreIcon style={{ color: "white" }} />

                                                    </div>
                                                    {city.departureDate ? (
                                                        <div >
                                                            <span className="calenderDay">{formatDatePart(city.departureDate, "day") || ""} </span>
                                                            <span className="calenderMonth" >{formatDatePart(city.departureDate, "month")}</span><br />
                                                            <span className="calenderYear" style={{ display: "block", marginTop: "10px" }}>{formatDatePart(city.departureDate, "year")}</span>
                                                        </div>
                                                    ) : ''}
                                                </div>

                                            )}
                                            onClick={(event) => {
                                                togglePicker('startDate', event, indexNumber)
                                            }}

                                            data-test-id="startDate"
                                            placeholder={city.departureDate ? "" : "Departure date"}
                                            variant="outlined"
                                            fullWidth
                                            InputProps={{
                                                classes: {

                                                    root: "custom-input-root",
                                                    input: "catalogue-header-box",
                                                },

                                            }}
                                            InputLabelProps={{

                                                className: "fontLabel",
                                                shrink: true,
                                                style: { transform: "translate(14px, 7px)", color: "#000", fontWeight: "700", fontSize: "16px" }
                                            }}
                                        />
                                    </Grid>
                                    {
                                        calenderBoxUiChild(indexNumber)
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3} lg={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start',padding:"0px" }}>
                                <Box style={{ display: "flex" }}>
                                    <img
                                        src={cancleCity}
                                        className="removeImg"
                                        data-testId="removeCity"
                                        onClick={() => handleRemoveCity(indexNumber)}
                                    />
                                    {indexNumber === state.cities.length - 1 && (
                                        <>
                                            <Button
                                                style={{display:'flex',gap:'4px',alignItems:'center'}}
                                                className="addCityButton"
                                                data-testId="addCity"
                                                onClick={() => handleAddCity()}
                                            >
                                                <AddIcon style={{fontSize:'14px'}} />add City
                                            </Button>
                                        </>
                                    )}
                                </Box>
                            </Grid>
                        </>
                    ))}
            </>
        );
    };
    const calenderBoxUiChild = (index: number) => {
        return (
            <Popover
                data-test-id="popover"
                open={state.isOpen && state.currentIndex === index}
                anchorEl={state.setAnchorAi}
                onClose={handleCloseCustomAi}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="boxCalenderDone111" style={{ borderRadius: "10px", width: "auto", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", zIndex: 9999, background: "#fff" }}>
                    <div className="datepikerTop111">
                        <div className="calTitle" style={{ padding: "12px 20px", width: "auto" }}>
                            <Typography variant="h4" ><span className="font14px">{state.startDate ? formatDate(state.startDate) : "Departure Date"}</span></Typography>
                        </div>

                        <div className="singleCalender calenderBoox">
                            <SingleCalender state={state} month={1} data-test-id="calenderBlock1" handleDateChange={handleDateChange1} />
                        </div>

                        <Box className="buttonBoxapply111">
                            <Button style={{ color: "rgba(23, 100, 160, 1)", fontSize: "16px", textTransform: "capitalize", fontWeight: "700" }} data-test-id="handleCancel" onClick={handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button style={{ background: "rgba(23, 100, 160, 1)", color: "#fff", fontSize: "16px", textTransform: "capitalize", fontWeight: "700" }} data-test-id="handleApply" onClick={() => handleApply(index)}>&nbsp;Apply&nbsp;</Button>
                        </Box>
                    </div>

                </div>
            </Popover>
        )
    }
    const travelInputText = () => {
        return (
            <>
                {state.fullTravelObj.countAdult &&
                    state.fullTravelObj.travelStatus ? (
                    <div>
                        <span  style={{fontSize:'16px'}} data-test-id="formatDay">{state.fullTravelObj.countAdult + state.fullTravelObj.countChild + state.fullTravelObj.countInfant} </span>
                        <span  style={{fontSize:'16px',textTransform:'capitalize'}} data-test-id="formatMonth">{state.fullTravelObj.countAdult + state.fullTravelObj.countChild + state.fullTravelObj.countInfant === 1 ? "Traveller" : "Travellers"}, {state.fullTravelObj.travelStatus}</span><br />
                    </div>
                ) : ''}
            </>
        )
    }
    return (
        <div className="tripFilterContainer">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={2}>
                    <div
                        className="tripTypeContainer"
                        data-testId="tripTypeOpen"
                        onClick={handleOpenTripType}
                    >
                        <Box style={{ width: '100%' }}>
                            <Box style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', paddingRight: '15px' }}>
                                <Typography className="catalogue-heading">
                                    Trip Type
                                </Typography>
                                <ExpandMoreIcon />
                            </Box>
                            <Typography className="selectresponsStyle catalogue-heading">{state.selectedTripType} </Typography>
                        </Box>
                    </div>
                    <Menu
                        marginThreshold={130}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        data-test-id="handleCloseTripType"
                        id="simple-menu"
                        anchorEl={state.anchorElTripType}
                        PaperProps={{
                            className: "tripMenu",

                        }}
                        data-testId="tripModalClose"
                        open={Boolean(state.anchorElTripType)}
                        onClose={closeTripTypeMenu}
                        keepMounted
                    >
                        <MenuList id="split-button-menu" autoFocusItem>
                            <MenuItem
                                className="catalogue-menu selectTravelType"
                                data-testId="selectMultiCity"
                                onClick={() => handleSelectTriptype("Multicity")}
                            >
                                Multicity
                            </MenuItem>
                            <MenuItem
                                className="catalogue-menu selectTravelType"
                                data-testId="selectMulRoundtrip"
                                onClick={() => handleSelectTriptype("Roundtrip")}
                            >
                                Roundtrip
                            </MenuItem>
                            <MenuItem
                                className="catalogue-menu selectTravelType"
                                data-testId="selectMulOneway"
                                onClick={() => handleSelectTriptype("ONE WAY")}
                            >
                                One Way
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={8}>
                    <div className='headerBox'><Box
                        className="catalogueHeaderBox"
                        data-testId="openMenuOption"
                        onClick={openTripOption}
                    >
                        <Box className="fromLableContainer">
                            <Typography className="catalogue-heading">From</Typography>
                            <ExpandMoreIcon className="expendMoreIcon" />
                        </Box>
                        <Typography className="from-heading catalogue-heading">
                            New Delhi To New Delhi,<Typography className="side-destinations catalogue-heading"> via Mumbai, Bangaluru</Typography>
                        </Typography>
                    </Box></div>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={2}
                    style={{ overflow: "hidden",cursor:'pointer' }}
                    
                >
                    <Box data-testId="openClassCategory" style={{position:'relative'}} onClick={openClassCategory} className="catalogue-header-box headerBox traveller-container">
                        <div  className="travellersLableContainer">
                            <div className="travellersLableBox">
                                <span className="catalogue-heading">
                                    Travellers & Class
                                </span>
                                <ExpandMoreIcon className="expendMoreIcon" />
                            </div>
                        </div>
                        {travelInputText()}
                    </Box>

                    <Menu
                        anchorEl={state.anchorElClass}
                        marginThreshold={72}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        data-test-id="handleCloseClass"
                        className="menuFrom dummtdes"
                        id="simple-menu"
                        PaperProps={{
                            style: {
                                width: 600,
                                borderRadius: "10px"
                            }
                        }}
                        open={Boolean(state.anchorElClass)}
                        onClose={closeClassCategory}
                        keepMounted

                    >

                        <Box style={{ padding: "10px" }}>
                            <Box className="classTravelHead">
                                <Typography style={{ fontSize: "12px", color: "#000", fontWeight: 700 }} variant="body1">{state.countAdult} {state.countAdult <= 1 ? "Adult" : "Adults"} , {state.countChild} {state.countChild <= 1 ? "Child" : "Children"}, {state.countInfant}  {state.countInfant <= 1 ? "Infant" : "Infants"}</Typography>
                                <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", }} variant="body1">{state.travelStatus}</Typography>
                            </Box>
                            <Box style={{ width: "100%", borderBottom: "1px solid rgba(226, 232, 240, 1)", marginTop: "10px" }}></Box>
                            <Box style={{ width: "100%", }}>

                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={4}>

                                        <Box sx={{ padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" }, textAlign: "center" }}>
                                            <Typography style={{ fontSize: "24px", color: "#000", fontWeight: "700" }} variant="body1">   {state.countAdult} {state.countAdult <= 1 ? "Adult" : "Adults"}</Typography>
                                            <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }}
                                                variant="body1">(Aged 18+years) </Typography>
                                            <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                                                <button className="borderNoneCount" data-test-id="decreaseCount" onClick={countIncrease}><RemoveIcon /></button>
                                                <div className="countShowText">{state.countAdult}</div>
                                                <button className="borderNoneCount" data-test-id="increaseCount" onClick={countDecrease}><AddIcon /></button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <Box sx={{ textAlign: "center", padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" } }}>

                                            <Typography style={{ fontSize: "24px", color: "#000", fontWeight: "700" }} variant="body1">  {state.countChild} {state.countChild <= 1 ? "Child" : "Children"}</Typography>
                                            <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }}
                                                variant="body1">(Aged 2-12 years)  </Typography>
                                            <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                                                <button className="borderNoneCount" data-test-id="decreaseCountChild" onClick={countChildDecrease}><RemoveIcon /></button>
                                                <div className="countShowText">{state.countChild}</div>
                                                <button className="borderNoneCount" data-test-id="increaseCountChild" onClick={countChildIncrease}><AddIcon /></button>

                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box sx={{ textAlign: "center", padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" } }}>
                                            <Typography style={{ fontSize: "24px", color: "#000", fontWeight: "700" }} variant="body1">   {state.countInfant} {state.countInfant <= 1 ? "Infant" : "Infants"}</Typography>
                                            <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }} variant="body1">(Under 2 years)  </Typography>
                                            <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }}
                                                className="countDivBox">

                                                <button className="borderNoneCount" data-test-id="decreaseCountChildInfant" onClick={countChildInfantDecrease}><RemoveIcon /></button>
                                                <div className="countShowText">{state.countInfant}</div>
                                                <button className="borderNoneCount" data-test-id="increaseCountChildInfant" onClick={countChildInfantIncrease}><AddIcon /></button>

                                            </Box>
                                        </Box>
                                    </Grid>

                                    {
                                        state.error &&
                                        <Grid item sm={12}>
                                            <Box className="ErrorMsg" sx={webStyleLanding.errorMsgforCount}>
                                                <span className="imgError " style={{ width: "5%", marginRight: "5px" }}>
                                                    <React.Suspense fallback={<CircularProgress />}>
                                                        <img src={errorIcon} />
                                                    </React.Suspense>
                                                </span> <span style={{ width: "95%" }}>

                                                    If you want to add more than 9 travellers please contact email <span style={{ textDecoration: "underline" }}>www.doolwa.com</span>
                                                </span>
                                            </Box>
                                        </Grid>
                                    }

                                    <Box sx={{
                                        textAlign: "center",
                                        width: "100%", padding: "0px 0 0px 0",
                                    }}>
                                        <Typography variant="body1" style={{ fontSize: "24px", color: "#000", fontWeight: "700" }} > Travel Class</Typography>
                                    </Box>
                                    <Grid item sm={12} data-test-id="allbutton">


                                        {allButtonList()}
                                    </Grid>

                                    <Box className="buttonBoxapply" style={{ padding: "10px", margin: "0px 10px" }}>
                                        <Button style={{ color: "rgba(23, 100, 160, 1)", fontSize: "16px", textTransform: "capitalize", fontWeight: "700" }} data-test-id="handleCancelClassTravel" onClick={handleCancelTravelButton}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button style={{ background: "rgba(23, 100, 160, 1)", color: "#fff", fontSize: "16px", textTransform: "capitalize", fontWeight: "700" }} data-test-id="handleDone" onClick={handleDoneButton}>&nbsp;Done&nbsp;</Button>
                                    </Box>
                                </Grid>
                            </Box>
                        </Box>
                    </Menu>
                </Grid>
                {renderInputTripList()}
            </Grid>
        </div>
    );

}

const webStyleLanding = {
    errorMsgforCount: {
        margin: "0px 10px",
        borderRadius: "5px",
        fontSize: "12px",
        borderLeft: "6px #c10f0ff0 solid",
        background: " #ed7e7e3d",
        fontFamily: "Inter, sans-serif",
        color: "#c12121",
        display: "flex",
        lineHeight: "24px",
        padding: "20px 15px",
        alignItems: "center"
    },
}

export default MulticyFlightHeader