import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import PackageLazyLoader from "../../../components/src/PackageLazyLoader";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../../components/src/Footer";
export const baseURL = require("../../../framework/src/config.js").baseURL;
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },

                               
});
const responsiveFirst = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, 
    partialVisibilityGutter: 180,
  },
  tablet: {
    slidesToSlide: 1,
    partialVisibilityGutter:0,
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    
  },
  mobile: {
    slidesToSlide: 1,
    partialVisibilityGutter:0,
    breakpoint: { max: 464, min: 0 },
    items: 1,
   
  }
};
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4, 
    partialVisibilityGutter: 180,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3,
    partialVisibilityGutter:0,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter:0,
  }
};
const responsiveOffer = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, 
    partialVisibilityGutter: 180,
  },
  tablet: {
    slidesToSlide: 3,
    partialVisibilityGutter:0,
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    
  },
  mobile: {
    slidesToSlide: 1,
    partialVisibilityGutter:0,
    breakpoint: { max: 464, min: 0 },
    items: 1,
   
  }
};
import PackageSearch from "./PackageSearch.web";
// Customizable Area End

import CfitinerarymanagementController, {
  Props,
  configJSON,
} from "./CfitinerarymanagementController";

export default class Cfitinerarymanagement extends CfitinerarymanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  sliderUi=()=>{
    return(
      <>
      {
                this.state.sliderData.length>0?<Carousel
                responsive={responsiveFirst}
                infinite={true}
                removeArrowOnDeviceType={['tablet', 'mobile']}
                containerClass="carousel-container custom-carousel"
                partialVisbile={false}
                arrows={false} 
                draggable={false}   
  swipeable={false} 
                showDots={this.state.sliderData.length<=1?false:true} 
             >
              {this.state?.sliderData.map((itemSlider:any, index: number) => (
               <div key={index} >
                 <div  className="" style={{ position: "relative", overflow: "hidden",marginBottom:"30px" }}>
                 <React.Suspense fallback={<CircularProgress />}>
                  <div className="imgPkSelling">
                    <PackageLazyLoader src={baseURL+itemSlider.image} type="packageSlider" />
                  </div>
                  </React.Suspense>
                   <div style={{position: "absolute", bottom: "81px", left: "40px", width:"100%" }}>
                     <Typography className="fontSizepackages" style={webStylePackage.secTwoTitleSlider}> {itemSlider.title}</Typography>
                    </div>
                 </div>
               </div>
             ))}
             </Carousel>:null
              }
      </>
    )
  }
  bestSellingUI=()=>{
    return(
      <>
      {
                this.state.packageSellingData.length>0?<Carousel
                responsive={responsive}
                infinite={true}
                removeArrowOnDeviceType={['tablet', 'mobile']}
                containerClass="carousel-container"
                partialVisbile={false}
                 arrows
                className={this.state.packageSellingData.length>2?"":"coustomSlider"}
             >
              {this.state.packageSellingData.map((itemSell:any, index: number) => (
               <div key={index} data-test-id={`handleNavigateXPackage${index}`} onClick={() => this.handleClickBestDetails(itemSell.id)} 
                 style={{cursor:"pointer"}}>
                 <div style={{ position: "relative", overflow: "hidden",margin:"10px" }}>
                 <React.Suspense fallback={<CircularProgress />}>
                 <div className="imgPkSelling">
                    <PackageLazyLoader src={baseURL+itemSell.image} type="packageSelling" />
                  </div>
                  </React.Suspense>
                   <div style={{ marginTop: "10px", position: "absolute", bottom: "25px", left: "18px" }}>
                     <Typography style={webStylePackage.secTwoTitle}> {itemSell.title}</Typography>
                     <Typography style={webStylePackage.secTwoTitle2}>Starting at {itemSell.price.toLocaleString()}</Typography>
                     <Typography style={webStylePackage.secTwoTitle3}>{itemSell.other}</Typography>
                     <Button data-test-id={`handleClickBookDetails${index}`} style={webStylePackage.secTwoTitle4}>Book Now</Button>
                   </div>
                 </div>
               </div>
             ))}
             </Carousel>:
                <>
                <Box sx={{display:"flex", justifyContent:"center", width:"100%"}}>
                <Box sx={{display:"block", textAlign:"center"}}>

                  <React.Suspense fallback={<CircularProgress />}>
                    
                  </React.Suspense>
                   <Typography variant="body1" className="fontSizeNoData" style={{margin:"20px 0", fontSize:"24px", fontWeight:700}}>No Packages is Available</Typography>
                 </Box>
                </Box>
                 </>
              }
      </>
    )
  }
  inDestinationUI=()=>{
    return(
      <>
      {
                this.state.inDestinationData.length>0?<Carousel
                responsive={responsive}
                infinite={true}
                removeArrowOnDeviceType={['tablet', 'mobile']}
                containerClass="carousel-container"
                partialVisbile={false}
                 arrows
                className={this.state.inDestinationData.length>2?"":"coustomSlider"}
             >
              {this.state.inDestinationData.map((item:any, index: number) => (
               <div key={index} onClick={() => this.handleClickBestDetails(item.id)}  style={{cursor:"pointer"}}>
                 <div style={{ position: "relative", overflow: "hidden",margin:"10px" }}>
                 <React.Suspense fallback={<CircularProgress />}>
                 <div className="imgPkSelling">
                    <PackageLazyLoader src={baseURL+item.image} type="packageSelling" />
                  </div>
                  </React.Suspense>
                   <div style={{ marginTop: "10px", position: "absolute", bottom: "25px", left: "18px" }}>
                     <Typography style={webStylePackage.secTwoTitle}> {item.title}</Typography>
                     <Typography style={webStylePackage.secTwoTitle2}>Starting at {item.price.toLocaleString()}</Typography>
                     <Typography style={webStylePackage.secTwoTitle3}>{item.other}</Typography>
                     <Button data-test-id={`handleClickBookDetailsa${index}`} style={webStylePackage.secTwoTitle4}>Book Now</Button>
                   </div>
                 </div>
               </div>
             ))}
             </Carousel>:
                <>
                <Box sx={{display:"flex", justifyContent:"center", width:"100%"}}>
                <Box sx={{display:"block", textAlign:"center"}}>
                  <React.Suspense fallback={<CircularProgress />}>
                  </React.Suspense>
                   <Typography variant="body1" className="fontSizeNoData" style={{margin:"20px 0", fontSize:"24px", fontWeight:700}}>No Destination is Available</Typography>
                 </Box>
                </Box>
                 </>
              }
      </>
    )
  }
  offersUI=()=>{
    return(
      <>
      {
                this.state.offersData.length>0?<Carousel
                responsive={responsiveOffer}
                infinite={true}
                removeArrowOnDeviceType={['tablet', 'mobile']}
                containerClass="carousel-container"
                partialVisbile={false}
                 arrows
                className={this.state.offersData.length>2?"":"coustomSlider"}
             >
              {this.state.offersData.map((item:any, index: number) => (
                <div key={index} style={{cursor:"pointer"}} data-test-id={`handleNavigateXOffer${index}`} onClick={()=>this.handleOffersDetails(item.id)}>
                <Box style={{...webStylePackage.cardStyle}}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={4}>
                    <React.Suspense fallback={<CircularProgress />}>
                    <div className="imgOffers">
                    <PackageLazyLoader data-test-id="oceanbackground" src={item.image} type="packageOffers" />
                  </div>
                    </React.Suspense>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                    <Typography style={webStylePackage.secTwoTitleOffers}>{this.truncateString(item.title, 29)}</Typography>
                   <Typography style={webStylePackage.secTwoTitle6}>{item.offer_code}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{display:"flex", justifyContent:"end"}}>
                      <Button data-test-id="handleClickBlogDetails" style={webStylePackage.secTwoTitle7} >Book Now</Button>
                      </Box>
                    </Grid>
                  </Grid>
                 
                </Box>
              </div>
             
             ))}
             </Carousel>:
                <>
                <Box sx={{display:"flex", width:"100%", justifyContent:"center"}}>
                <Box sx={{display:"block", textAlign:"center"}}>
                  <React.Suspense fallback={<CircularProgress />}>
                  </React.Suspense>
                   <Typography variant="body1"
                    className="fontSizeNoData" 
                    style={{margin:"20px 0", fontSize:"24px", fontWeight:700}}>No Offer is Available</Typography>
                 </Box>
                </Box>
                 </>
              }
      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
      <Loader loading={this.state.loading} />
      <PackageSearch ref={this.childRef} data-test-id="PackageSearch" navigation={this.props.navigation} id={""} package={""} onSendData={this.handleChildData}  />
     <Box data-test-id={'boxGridaa'} sx={{ padding:{xs:"10px 10px",sm:"10px 10px",md:"10px 30px",lg:"10px 60px" }, }}>
     <Grid container>
            <Grid item xs={12}>
              {this.sliderUi()}
            </Grid>
          </Grid>
     <Grid container>
           <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
            <Grid item className="textH5" style={webStylePackage.textAlignStart}>
                <Typography className="marginleft10px" variant="h5">Best Selling Packages</Typography>
              </Grid>
              <Grid item className="textH5" style={webStylePackage.viewAllStyle}>
                  <span 
                  >
                     {this.state.packageSellingData.length>0 && 
                    <Typography variant="h5" data-test-id={`handleViewAll${1}`} className="fontViewAll" onClick={()=>this.handleViewAll("ViewAllPackages")} style={{ marginRight: "20px", color: "rgb(23, 100, 160)", fontSize: "20px",cursor:"pointer" }}>View All</Typography>
                     }
                    </span>
              </Grid>
           </div>
            <Grid item xs={12}>
              {this.bestSellingUI()}
            </Grid>
          </Grid>

          <Grid container>
           <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
            <Grid item className="textH5" style={webStylePackage.textAlignStart}>
                <Typography className="marginleft10px" variant="h5">International Destinations</Typography>
              </Grid>
              <Grid item className="textH5" style={webStylePackage.viewAllStyle}>
                  <span >
                    {this.state.inDestinationData.length>0 && 
                    <Typography variant="h5" className="fontViewAll" style={{ marginRight: "20px", color: "rgb(23, 100, 160)",
                     fontSize: "20px",cursor:"pointer" }} onClick={()=>this.handleViewAll("ViewAllInternationalPackages")}>View All</Typography>
                    }
                  </span>
              </Grid>
           </div>
            <Grid item xs={12}>
              {this.inDestinationUI()}
            </Grid>
          </Grid>

          <Grid container>
           <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
            <Grid item className="textH5" style={webStylePackage.textAlignStart}>
                <Typography className="marginleft10px" variant="h5">Offers</Typography>
              </Grid>
              <Grid item className="textH5" style={webStylePackage.viewAllStyle}>
                  <span >
                  {this.state.offersData.length>0 && 
                    <Typography  onClick={()=>this.handleViewAll("ViewAllOfferPackage")} variant="h5" className="fontViewAll" style={{ marginRight: "20px", color: "rgb(23, 100, 160)",
                     fontSize: "20px",cursor:"pointer" }}>View All</Typography>
                  }
                  </span>
              </Grid>
           </div>
            <Grid item xs={12}>
              {this.offersUI()}
            </Grid>
          </Grid>
     </Box>
     <Footer />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStylePackage:any = {
  viewAllStyle: {
    textAlign: "end" as "end",
    marginTop: "30px",
    marginBottom: "30px",
    fontWeight:700,

  },
  textAlignStart: {
    textAlign: "start" as "start",
    marginTop: "30px",
    marginBottom: "30px",
    fontWeight:700,


  },
  secTwoTitleOffers:{
    padding: "5px 10px",
    fontSize: "24px",
    color:"#000",
    fontWeight: "700"
  },
  secTwoTitleSlider1:{
    padding: "5px 10px",
    fontSize: "32px",
    color:"#fff",
    fontWeight: "400"
  },
  secTwoTitleSlider:{
    padding: "5px 10px",
    fontSize: "32px",
    color:"#fff",
    fontWeight: "700"
  },
  secTwoTitle:{
  padding: "5px 10px",
  fontSize: "24px",
  color:"#fff",
  fontWeight: "700"
  },
  secTwoTitle7:{
    fontSize: "14px",
    width:"fit-content",
    background: "rgba(23, 100, 160, 1)",
    borderRadius: "8px",
    padding: "10px 12px 10px 12px",
    fontWeight: "700",
    color: "#fff",
    textTransform: "capitalize" as "capitalize",
    marginTop: "-10px",
    marginRight:"10px",
    cursor: "pointer"
  },
  secTwoTitle4:{
    fontSize: "14px",
    width:"fit-content",
    background: "white",
    borderRadius: "8px",
    padding: "10px 12px 10px 12px",
    fontWeight: "700",
    color: "rgba(23, 100, 160, 1)",
    textTransform: "capitalize" as "capitalize",
    marginTop: "10px",
    marginLeft:"5px",
    cursor: "pointer"
  },
  secTwoTitle3:{
    color:"#fff",
    padding: "5px 10px",
    fontSize: "14px",
    display: "flex",
  alignItems: "center"
  },
  secTwoTitle6:{
    color:"#000",
    padding: "5px 10px",
    fontSize: "16px",
    fontWeight: "700",
    display: "flex",
  alignItems: "center"
  },
  secTwoTitle2:{
    color:"#fff",
    padding: "5px 10px",
    fontSize: "14px",
    fontWeight: "700",
    display: "flex",
  alignItems: "center"
  },
  cardStyle: {
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px",
    borderRadius: "15px",
    margin: "7px",
    overflow:"hidden",
    padding:"15px"

  },
};
// Customizable Area End
