import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  CircularProgress
  // Customizable Area End


} from "@material-ui/core";
// Customizable Area Start
import PackageLazyLoader from "../../../components/src/PackageLazyLoader";
export const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

import BestSellingViewAllController, {
  Props,
} from "./ViewAllSellingController.web";
import PackageSearch from "../../cfitinerarymanagement/src/PackageSearch.web";

export default class ViewAllSelling extends BestSellingViewAllController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <PackageSearch data-test-id="PackageSearch" package={"AllPackage"} navigation={this.props.navigation} id={""} onSendData={this.handleChildData} />
        <Box style={{ position: "relative" }}>
          <div className="content-container" style={{
            position: 'absolute',
            top: '-100px',
            width: '100%',
          }}>
            <Box sx={{ padding: { xs: "10px 10px", sm: "10px 10px", md: "10px 30px", lg: "10px 30px" }, }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Box className="boxShadowDetail" style={{ background: "#fff" }}>
                    <Grid item className="textH5" style={webStyleBestPackage.textAlignStart}>
                      <Typography className="marginleft10px" variant="h5">Best Selling Packages</Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      {this.state.packageSellingData.map((itemSell: any, index: number) => (
                        <Grid item xs={12} sm={6} md={4}>
                          <div key={index} data-test-id={`handleNavigateXPackages${index}`}

                           onClick={() => this.handleClickSellingDetails(itemSell.id)}  style={{ cursor: "pointer" }}>
                            <div style={{ position: "relative", overflow: "hidden", margin: "10px" }}>
                              <React.Suspense fallback={<CircularProgress />}>
                                <div className="imgPkSelling">
                                  <PackageLazyLoader src={baseURL + itemSell.image} type="packageSelling" />
                                </div>
                              </React.Suspense>
                              <div style={{ marginTop: "10px", position: "absolute", bottom: "25px", left: "18px" }}>
                                <Typography style={webStyleBestPackage.secTwoTitle}> {itemSell.title}</Typography>
                                <Typography style={webStyleBestPackage.secTwoTitle2}>Starting at {itemSell.price.toLocaleString()}</Typography>
                                <Typography style={webStyleBestPackage.secTwoTitle3}>{itemSell.other}</Typography>
                                <Button data-test-id="handleClickBlogDetails" style={webStyleBestPackage.secTwoTitle4} >Book Now</Button>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      ))}




                    </Grid>
                  </Box>
                </Grid>
              </Grid>

            </Box>
          </div>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyleBestPackage: any = {
  textAlignStart: {
    textAlign: "start" as "start",
    marginTop: "30px",
    marginBottom: "30px",
    fontWeight: 700,
  },
  secTwoTitle3: {
    color: "#fff",
    padding: "5px 10px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center"
  },
  secTwoTitle4: {
    fontSize: "14px",
    width: "fit-content",
    background: "white",
    borderRadius: "8px",
    padding: "10px 12px 10px 12px",
    fontWeight: "700",
    color: "rgba(23, 100, 160, 1)",
    textTransform: "capitalize" as "capitalize",
    marginTop: "10px",
    marginLeft: "5px",
    cursor: "pointer"
  },
  secTwoTitle: {
    padding: "5px 10px",
    fontSize: "24px",
    color: "#fff",
    fontWeight: "700"
  },
  secTwoTitle2: {
    color: "#fff",
    padding: "5px 10px",
    fontSize: "14px",
    fontWeight: "700",
    display: "flex",
    alignItems: "center"
  },
};
// Customizable Area End

