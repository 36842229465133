import React from "react";
// Customizable Area Start
import "../assets/styles.css"
import { Box,Grid  } from "@material-ui/core";
import { InfoOutlined,ArrowLeft, ArrowRight } from "@material-ui/icons";
import { plusIcon,BookedSeatIcon,SelectedSeatByUserIcon,SelectedSeatIcon,SeatExitRightIcon,SeatExitIcon,ExitIcon } from "./assets";
import HeaderMenuBlock from "../../navigationmenu/src/HeaderMenu.web";
// Customizable Area End

import Cfseatselection1Controller, {
  Props,
  configJSON,
} from "./Cfseatselection1Controller";

export default class Cfseatselection1 extends Cfseatselection1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  oneWaySeatSelection = (item:any, index:any) => {
    const middleRowIndex = Math.floor(item.Row.length / 2);
    return (
      <Box data-test-id ="oneway_seat" sx={webstyle.seatSelectParent}>
        <div className="seat-selection-top-container">
          <div className="seat-selection-toprow">
            <span className="seat-heading">Seat Selection</span>
            <span className="skip-btn">Skip</span>
          </div>
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <div className="seat-selected">
              <span className="total-text">{item.AirSegment.Origin} - {item.AirSegment.Destination}</span>
              <span className="fare-name">0 of {item.Traveldata.length} Seat Selected</span>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: 'center' }}>
          <div className="seat-book-container">
              <div className="seat-book-container-inner">
                <div className="exit-left">
                  <img className="exit-display-icon" src={ExitIcon}/>
                  <span className="exit-text" style={{display:"flex",alignItems:"center"}}>
                  <ArrowLeft /> EXIT
                  </span>
                </div>
                <div className="exit-right">
                  <img className="exit-display-icon" src={ExitIcon}/>
                  <span className="exit-text" style={{display:"flex",alignItems:"center"}}>
                  EXIT <ArrowRight />
                  </span>
                </div>
              </div>
            {item.Row.map((row:any, rowIndex:any) => (
              <div key={rowIndex} className="seat-row-container">
                <div className="row-index-label">{rowIndex}</div>
                <div key={rowIndex} className="seat-row">
                  {row.map((seat:any, seatIndex:any) => {
                    let seatContent;     
                    switch (seat.availability) {
                      case 'booked':
                        seatContent = (
                          <div className="bookedicon seat-display">
                            <img src={BookedSeatIcon} alt="Booked Seat" className="info-icon" />
                          </div>
                        );
                        break;
                        case 'blocked':
                        seatContent = (
                          <div className="bookedicon seat-display">
                            <img src={BookedSeatIcon} alt="Booked Seat" className="info-icon" />
                          </div>
                        );
                        break;
                        case 'noseat':
                          seatContent = (
                            <div className="bookedicon seat-display">
                              <img src={BookedSeatIcon} alt="Booked Seat" className="info-icon" />
                            </div>
                          );
                          break;
                      case 'selectedByUser':
                        seatContent = (
                          <div onClick={()=>this.props.availableFlight(rowIndex,seatIndex,index,item.Traveldata)} className="bookedicon seat-display">
                            <img src={SelectedSeatByUserIcon} alt="Selected Seat" className="info-icon" />
                          </div>
                        );
                        break;
                      case 'paidAvailable':
                        seatContent = <div className="paid-seat-icon bookedicon seat-display"></div>;
                        break;
                      case 'available':
                        seatContent = <div onClick={()=>this.props.availableFlight(rowIndex,seatIndex,index,item.Traveldata)} className="available-paid-seat bookedicon seat-display"></div>;
                        break;
                      case 'clicked':
                        seatContent = (
                          <div className="bookedicon seat-display" >
                            <img src={SelectedSeatIcon}  alt="Selected Seat" className="info-icon" />
                          </div>
                        );
                        break;
                      case 'occupied':
                        seatContent = <div className="bookedicon seat-display occupiedIcon"></div>;
                        break;
                      case 'free':
                        seatContent = <div className="bookedicon seat-display free-seat-icon"></div>;
                        break;
                      case 'reservation':
                        seatContent = <div className="bookedicon seat-display reservation-icon"></div>;
                        break;
                    }
  
                    return (
                      <div key={seatIndex} className={`seat ${seatIndex === 3 ? 'seat-gap' : ''}`}>
                        {seatContent}
                      </div>
                    );
                  })}
                </div>
                {rowIndex === middleRowIndex && (
                  <div className="exit-doors-container">
                    <div className="exit-left">
                      <img src={SeatExitIcon} alt="" className="seat-exit-left" />
                      <img src={SeatExitIcon} alt="" className="seat-exit-left" />
                    </div>
                    <div className="exit-right">
                      <img src={SeatExitRightIcon} alt="" className="seat-exit-left" />
                      <img src={SeatExitRightIcon} alt="" className="seat-exit-left" />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="seat-container">
          <div className="seat-details-cont">
            {this.seatSelectDeatils()}
          </div>
        </div>
      </Box>
    );
  }
  
  seatSelectDeatils = ()=>{
    return(
      <Grid data-test-id ="seat-details" container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <div className="seat-detail">
            <div className="bookedicon">
              <img src={BookedSeatIcon} alt="" className="info-icon" />
            </div>
            <span className="seat-details-text">Booked Seats</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <div className="seat-detail">
            <div className="bookedicon activecheck">
              <img src={SelectedSeatByUserIcon} alt="" className="info-icon" />
            </div>
            <span  className="seat-details-text">Selected Seat by the active traveller</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <div className="seat-detail">
            <div className="paid-seat-icon bookedicon">
            </div>
            <span  className="seat-details-text">Available Paid Seat</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <div className="seat-detail">
            <div className="available-paid-seat bookedicon">
            
            </div>
            <span className="seat-details-text"> Available Paid Seat </span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <div className="seat-detail">
            <div className="bookedicon">
              <img src={SelectedSeatIcon} alt="" className="info-icon" />
            </div>
            <span className="seat-details-text"> Selected Seat </span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <div className="seat-detail">
            <div className="bookedicon occupiedIcon"> 
            </div>
            <span className="seat-details-text"> Occupied </span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <div className="seat-detail">
            <div className="bookedicon free-seat-icon">
            </div>
            <span className="seat-details-text"> Available Free Seat </span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <div className="seat-detail">
            <div className="bookedicon reservation-icon outlineseat">
            </div>
            <span className="seat-details-text"> Available  after Reservation is purchased </span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <div className="seat-detail">
            <div className="bookedicon exit-icon">
            </div>
            <span className="seat-details-text"> Exit Row </span>
          </div>
        </Grid>
    </Grid>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        {
           this.props.wholeDataMatrix.map((item:any, index:any)=>(
            this.oneWaySeatSelection(item, index)
           ))
        }
       
       
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webstyle ={
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "56px",
    overFlowX: 'hidden',
    height: {
      xs: '930px',
      sm: '800px',
      md: '580px',
      lg: '490px'
    },
    background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
  },
  contentContainer :{
    position: "absolute",
    top:"100px",
    width:"100%",
    display:"flex",
    flexDirection:"column",
    gap: "50px"
  },
  contentParent:{
    display:"flex",
    gap:"16px",
    alignItems:"flex-start",
    paddingLeft:"16px",
    paddingRight:"16px",
  },
  fareDetails:{
    borderBottom:"2px solid black"
  },
  seatSelectParent:{
    width: {
      // xs: '930px',
      // sm: '800px',
      md: '100%',
      lg: '100%'
    },
    backgroundColor:"#E9F3FC",
    minHeight:"500px",
    boxShadow: "0px 4px 10px 0px #00000029",
    borderRadius:"8px",
    display:"flex",
    flexDirection:"column",
    gap:"48px",
    paddingBottom:"50px"
  }
}


// Customizable Area End
