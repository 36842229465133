import React from "react";
// Customizable Area Start
import { Box, Typography, Checkbox, Slider } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import "../assets/styles.css"
import FilteritemsController, { Props } from "./FilteritemsController.web";
// Customizable Area End

// Customizable Area Start
interface TimePriceProps {
    time: string;
    price: string;
}

interface AirlinesProps {
    id: number;
    AirlineName: string;
    Count:number;
    checked:boolean;
}

interface PopularFilterProps {
    checked: boolean;
    name: string;
}
const BpIcon = styled('span')(({ theme }) => ({
  
    borderRadius: 6,
    border: '1px solid #64748B',
    width: 32,
    height: 32,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        'rgba(206,217,224,.5)',
    },
    [theme.breakpoints.up('xs')]: {
      width: 18,
      height: 18,
    },
    [theme.breakpoints.up('sm')]: {
      width: 18,
      height: 18,
    },
    [theme.breakpoints.up('md')]: {
      width: 24,
      height: 24,
    },
    [theme.breakpoints.up('lg')]: {
      width: 32,
      height: 32,
    },
  }));
  
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#137cbd',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
      display: 'block',
      width: '100%',
      height: '100%',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  });
const StyledSlider = styled(Slider)(({ theme }) => ({
    color: "#1764A0",
    height: 12,
    padding: "20px 0",
    "& .MuiSlider-thumb": {
        height: 25,
        width: 25,
        backgroundColor: "#fff",
        border: "1px solid #f7f7f7",
        boxShadow: "4px 4px 10px 0px #00000029",
        "&:focus, &:hover, &.Mui-active": {
            boxShadow: "4px 4px 10px 0px #00000029",
            "@media (hover: none)": {
                boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
            },
        },
        "&:before": {
            boxShadow:
                "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
        },
    },
    "& .MuiSlider-valueLabel": {
        fontSize: 12,
        fontWeight: "normal",
        top: -6,
        backgroundColor: "unset",
        color: theme.palette.text.primary,
        "&::before": {
            display: "none",
        },
        "& *": {
            background: "transparent",
            color: "#000",
        },
    },
    "& .MuiSlider-track": {
        border: "none",
        height: 12,
        borderRadius: "10px"
    },
    "& .MuiSlider-rail": {
        opacity: 0.5,
        height: 12,
        boxShadow: "inset 0px 0px 4px -2px #000",
        backgroundColor: "#1764A0",
        borderRadius: "10px"
    },
}));
const popularFilterItemStyle = {
    container: {
        display: "flex",
        alignItems: "center",
        marginBottom: "16px",
        marginTop: "16px",
        paddingLeft: "24px",
        gap: "6px",
        borderBottom: "1px solid #CBD5E1"
    },
    content: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        paddingRight: "30px",
    },
    imageContent: {
        display: "flex",
        gap: "16px"
    },
};

// Customizable Area End

export default class Filteritems extends FilteritemsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    PopularList(item: PopularFilterProps, index: number) {
        const { name, checked } = item;
        return (
            <Box data-test-id={`PopularFilter${index}`} sx={popularFilterItemStyle.container} key={name} className="popularFilters-list">
                <Checkbox
                    data-test-id={`PopularFilterCheckbox${index}`}
                    value={checked}
                    checked={checked}
                    onChange={() => this.handlePopularFilterSelected(item)}
                    color="default"
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                />
                <Box sx={popularFilterItemStyle.content} className="popularFilters-list-item">
                    <Box sx={popularFilterItemStyle.imageContent} className="popular-filters-img-parent">
                        <Typography variant="body1" className="popular-filters-content">{name}</Typography>
                    </Box>
                </Box>
            </Box>
        );
    }
    AirlineFilter(item: AirlinesProps, index: number) {
        const { AirlineName, Count, checked } = item;
        return (
            <Box data-test-id={`Airline${index}`} sx={popularFilterItemStyle.container} key={AirlineName} className="popularFilters-list">
                <Checkbox
                    color="default"
                    checkedIcon={<BpCheckedIcon />}
                    icon={<BpIcon />}
                    data-test-id={`AirlineCheckbox${index}`}
                    value={checked}
                    checked={checked}
                    onChange={() => this.handleSelecedtAirLines(item)}
                />
                <Box sx={popularFilterItemStyle.content} className="popularFilters-list-item">
                    <Box className="popular-filters-img-parent" sx={popularFilterItemStyle.imageContent}>
                        {AirlineName && <img className="popular-filters-img" src={"https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg"}  style={{ width: "24px", height: "24px", borderRadius: "50%", objectFit: "cover" }} alt="icon" />}
                        <Typography className="popular-filters-content" variant="body1">{AirlineName}</Typography>
                    </Box>
                    <Typography className="popular-filters-content" variant="body1" >{Count}</Typography>
                </Box>
            </Box>
        );
    }
    TimeCardList(item: TimePriceProps, index: number) {
        const { time } = item
        const deptCardSelected = this.props.departureTimePrice?.time === time;
        return (
            <Box data-test-id={`DepartureTime${index}`} sx={{
                border: deptCardSelected ? "2px solid #1764A0" : "2px solid #64748B",
                borderRadius: "16px",
                width: "90px",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                gridRowGap: "5px",
                alignItems: "center",
                justifyContent: "center"
            }} style={{ cursor: "pointer" }} onClick={() => this.handleDepartureTimeSelect(item)} key={index} className="time-card">
                <Typography style={{
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 600,
                    fontSize: "10px",
                    color: deptCardSelected ? "#1764A0" : "#94A3B8",
                    width: "92px", display: "flex", justifyContent: "center"
                }}>{time}</Typography>
            </Box>
        )

    }

    ArrivalTimeCardList(item: TimePriceProps, index: number) {
        const { time } = item;
        const cardSelected = this.props.arrivalTimePrice?.time === time;
        return (
            <Box data-test-id={`ArrivalTime${index}`} sx={{
                border: cardSelected ? "2px solid #1764A0" : "2px solid #64748B",
                borderRadius: "16px",
                width: "90px",
                height: "90px",
                display: "flex",
                flexDirection: "column",
                gridRowGap: "5px", alignItems: "center",
                justifyContent: "center"
            }} style={{ cursor: "pointer" }} key={time} className="time-card" onClick={() => this.handleArrivalTimeSelect(item)}>
                <Typography style={{
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 600,
                    fontSize: "10px",
                    color: cardSelected ? "#1764A0" : "#94A3B8",
                    width: "92px", display: "flex", justifyContent: "center"
                }}>{time}</Typography>
            </Box>
        );
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { timePriceArray, shownPopularFilters } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <div data-test-id="popular-filter" style={{ width: "100%" }} className={`filter-container`}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    width: "auto",
                    height: "fit-content",
                    bgcolor: "white",
                    marginLeft: "21px",
                    borderRadius: "8px",
                    paddingBottom: "32px",
                    gridGap: "40px"
                }} className="filter-container-box" data-test-id="filters">
                   <Box sx={{justifyContent:"space-between", display:"flex", alignItems:"center"}}>
                   <Typography data-test-id="popular-filters-heading" style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 700,
                        fontSize: "24px",
                        marginLeft: "32px",
                        marginTop: "32px"
                    }} className="popular-filters-heading">Popular Filters</Typography>
                    <Box sx={{marginTop:"32px", marginRight: "32px",fontFamily: "Inter, sans-serif",
                        fontWeight: 700,}}>
                       <span onClick={this.props.clearAllFunct} style={{cursor:"pointer", color:"rgb(23, 100, 160)"}}> Clear All</span>
                    </Box>
                   </Box>
                    <Box data-test-id="popular-filters-options" style={{ marginTop: "10px" }}>
                       
                        {this.props.shownPopularFilters.map((item, index: number) =>
                             this.PopularList(item, index)
                        )}
                        {this.state.showPopularMore ? <div data-test-id="popular-filters-showLess" style={{ cursor: "pointer", display:"none" }} onClick={() => this.showLessPopularFiltersList()}>
                            <Typography style={{
                                fontFamily: "Inter, sans-serif",
                                fontWeight: 700,
                                fontSize: "14px",
                                color: "#1764A0",
                                marginLeft: "24",
                                cursor: "pointer"
                            }}>Show Less</Typography></div> :
                            <div style={{ cursor: "pointer",display:"none" }} data-test-id="popular-filters-showmore" onClick={() => this.showMorePopularFiltersList()}>
                                <Typography style={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    color: "#1764A0",
                                    marginLeft: "24",
                                    cursor: "pointer"
                                }}>+{this.state.popularFilters.length} More</Typography>
                            </div>}
                    </Box>
                    <div className="filter-middle-block" data-test-id="middle-filters" style={{ display: "flex", flexDirection: "column", gap: "32px", paddingLeft: "28px", paddingRight: "28" }}>
                        <Box data-test-id="price-filter" sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}>

                            <Typography style={{
                                fontFamily: "Inter, sans-serif",
                                fontWeight: 700,
                                fontSize: "24px",
                                marginBottom: "-4px"
                            }}> Price</Typography>
                            <StyledSlider data-test-id="slider" min={typeof this.props.minPrice === "number" ? this.props.minPrice : 0}
                                max={typeof this.props.maxPrice === "number" ? this.props.maxPrice : 100}
                                onChange={this.handlePriceChange} value={this.props.selectedPrice} />
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "-2px"
                            }}>
                                <Typography style={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                }} className="price-range-content">INR {this.props.minPrice}</Typography>
                                <Typography style={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                }} className="price-range-content">INR {this.props.selectedPrice}</Typography>
                            </Box>

                        </Box>
                        <Typography data-test-id="departure-heading-filter" style={{
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 700,
                            fontSize: "24px",
                        }}>Departure from {this.props.inputCityFrom}</Typography>
                        <Box data-test-id="departure-filter" sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            gridGap: "10px",
                        }}>
                            {this.props.timePriceArray.map((item, index: number) =>
                                this.TimeCardList(item, index)
                            )}
                        </Box>
                        <Typography data-test-id="arrival-heading-filter" style={{
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 700,
                            fontSize: "24px",
                        }}>Arrival at {this.props.inputCityTo}</Typography>
                        <Box data-test-id="arrival-filter" sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            gridGap: "10px",
                        }}>
                            {this.props.timePriceArray.map((item, index: number) => 
                                this.ArrivalTimeCardList(item, index)
                            )}
                        </Box>
                        <Typography data-test-id="airlines-heading-filter" style={{
                            fontFamily: "Inter, sans-serif",
                            fontWeight: 700,
                            fontSize: "24px",
                        }}>Airlines</Typography>
                    </div>
                    <Box data-test-id="airline-filter" style={{ marginTop: "10px" }}>
                        {this.props.airLinesData.map((item, index: number) =>
                            this.AirlineFilter(item, index)
                        )}
                        {this.state.showAirlineMore ? <div data-test-id="airline-filters-showLess" style={{ cursor: "pointer", display:"none" }} onClick={() => this.showLessAirlineFiltersList()}>
                            <Typography
                                style={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    color: "#1764A0",
                                    marginLeft: "24",
                                    cursor: "pointer"
                                }}>Show Less</Typography></div> :
                            <div style={{ cursor: "pointer",display:"none" }} data-test-id="airline-filters-showmore" onClick={() => this.showMoreAirlineFiltersList()}>
                                <Typography style={{
                                    fontFamily: "Inter, sans-serif",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    color: "#1764A0",
                                    marginLeft: "24",
                                    cursor: "pointer"
                                }}>+{this.state.airlinesList.length} More</Typography>
                            </div>}
                    </Box>
                </Box>
            </div>
            // Customizable Area End
        );
    }
}